@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-white text-gray-900 antialiased min-h-screen;
}

:root.dark {
    body {
        @apply bg-black text-gray-200;
    }

    .statRow:nth-child(even) {
      @apply bg-gray-900;
    }

    .gameSelectorInput {
      @apply border-white bg-black;
    }

    .gameSelector select {
      @apply border-white bg-black;
    }

    .statRow:hover {
      @apply bg-indigo-800;
    }
}

tr {
    @apply text-xs;
}

th.playerName {
  @apply w-36;
}

th {
  @apply text-right;
}

.statCell {
  @apply w-6 text-right;
}

.statRow:nth-child(even) {
  @apply bg-gray-100;
}

.statRow:hover {
  @apply bg-yellow-200;
}

.gameSelectorLabel {
  @apply w-60;
}

.gameSelectorInput {
  @apply w-12 border border-black mx-2;
}

.gameSelector select {
  @apply border border-black;
}

.statCard {
  @apply my-2;
}

.displayModeButton {
  @apply bg-transparent cursor-pointer;
}

.footer {
  @apply text-gray-500 italic text-xs;
}
